import { SetState } from "interfaces";
import { createContext, FC, useContext, useState } from "react";

const GlobalsContext = createContext({
  cartOpen: false,
  setCartOpen: (() => {}) as SetState<boolean>,
  helperOpen: true,
  setHelperOpen: (() => {}) as SetState<boolean>,
});

export const useGlobals = () => useContext(GlobalsContext);
export const toggle = (s: SetState<any>) => s((p) => !p);
export const GlobalsProvider: FC = (props) => {
  const [cartOpen, setCartOpen] = useState(false);
  const [helperOpen, setHelperOpen] = useState(true);
  return (
    <GlobalsContext.Provider
      {...props}
      value={{
        cartOpen,
        setCartOpen,
        helperOpen,
        setHelperOpen,
      }}
    />
  );
};
