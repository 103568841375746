interface DateOptions extends Intl.DateTimeFormatOptions {
  showTime?: boolean;
  formatBy?:
    | void
    | ((obj: { date: string; month: string; year: string }) => string);
}

const timeDefaults: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const formatDate = (
  dateObj: Date | string,
  {
    showTime,
    formatBy = ({ date, month, year }) => `${date}/${month}/${year}`,
    ...options
  }: DateOptions = {}
): string => {
  if (typeof dateObj === "string")
    return formatDate(new Date(dateObj), { showTime, formatBy, ...options });

  const opt: DateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    ...(showTime ? timeDefaults : {}),
    ...options,
  };

  const dateString = dateObj.toLocaleDateString("en-IN", opt);

  if (!formatBy) return dateString;

  const [date, month, year] = dateString
    .split(" ")
    .map((i) => i.split("/"))
    .flat();

  return formatBy({ date, month, year });
};

interface NumberOptions {
  decimals?: number;
  alpha?: boolean;
}

const alphaValues = [
  { above: 1000, letter: "K" },
  { above: 1000000, letter: "M" },
];

export const formatNumber = (
  n: number,
  { decimals = 2, alpha = false }: NumberOptions = {}
) => {
  var [num, suffix] = alpha
    ? alphaValues.reduce(
        ([num, suff], { above, letter }) => [
          n >= above ? n / above : num,
          n >= above ? letter : suff,
        ],
        [n, ""]
      )
    : [n, ""];

  const ret = Intl.NumberFormat("en", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(num);
  return ret + suffix;
};

export const getInt = (str: string) => {
  try {
    return parseInt(str) || 0;
  } catch (error) {
    return 0;
  }
};
