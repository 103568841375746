import { auth, logout } from "config/firebase";
import { onAuthStateChanged, User } from "firebase/auth";
import useLocalStorage from "hooks/useLocalStorage";
import { Profile } from "interfaces";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { fetchProfile, fetchSessionCreds } from "services/users";
import { getLocal } from "utils/getLocal";

const UserContext = createContext<{
  user?: User;
  profile?: Profile;
  idToken: string;
  setSessionsToken: Dispatch<SetStateAction<string>>;
  setSessionsId: Dispatch<SetStateAction<string>>;
  sessionsId?: string;
  sessionsToken?: string;
  setProfile: Dispatch<SetStateAction<Profile>>;
  sessionsChange: string;
  clearSession: () => void;
  safeLogout: () => Promise<void>;
}>({
  idToken: "",
  setSessionsId: () => {},
  setSessionsToken: () => {},
  setProfile: () => {},
  sessionsChange: "",
  clearSession: () => {},
  safeLogout: async () => {},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: FC = (props) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [idToken, setIdToken] = useLocalStorage<string>("id_token");
  const [sessionsId, setSessionsId] = useLocalStorage<string>("sessions_id");
  const [sessionsToken, setSessionsToken] = useLocalStorage<string>(
    "sessions_token"
  );

  const sessionsChange: string | undefined =
    typeof window === "undefined" ||
    !getLocal("sessions_token") ||
    !getLocal("sessions_id")
      ? undefined
      : getLocal("sessions_token") + getLocal("sessions_id");

  useEffect(() => {
    if (!sessionsId || !sessionsToken) return;
    fetchProfile().then((r) => setProfile(r.body));
  }, [sessionsId, sessionsToken]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        user.getIdToken().then(setIdToken);
      } else {
        setIdToken(undefined);
        if (window) window.localStorage.removeItem("access_token");
      }
    });
  }, []);

  useEffect(() => {
    getSessionCreds();
    if (!idToken) setProfile(undefined);
  }, [idToken]);

  const getSessionCreds = async () => {
    // if (!idToken) {
    //   setSessionsId(undefined);
    //   setSessionsToken(undefined);
    //   return;
    // }
    // if (!sessionsId || !sessionsToken)
    if (idToken) {
      const data = await fetchSessionCreds();
      setSessionsId(data.body?.session_id);
      setSessionsToken(data.body?.session_token);
    }
  };

  const clearSession = () => {
    setSessionsId(undefined);
    setSessionsToken(undefined);
  };

  const safeLogout = async () => {
    await logout();
    clearSession();
  };

  return (
    <UserContext.Provider
      {...props}
      value={{
        user,
        profile,
        idToken,
        setSessionsId,
        setSessionsToken,
        sessionsId,
        sessionsToken,
        setProfile,
        sessionsChange,
        clearSession,
        safeLogout,
      }}
    />
  );
};
