import { ROUTES } from "config/api";
import { Profile } from "interfaces";
import { createFetcher } from "./helpers";

export const fetchSessionCreds = createFetcher.post(ROUTES.USERS.AUTH, {
  addFirebaseCreds: true,
});

export const fetchProfile = createFetcher.post<Profile>(ROUTES.USERS.PROFILE, {
  addSessionCreds: true,
});

export const updateUser = createFetcher.post(ROUTES.USERS.EDIT, {
  addSessionCreds: true,
});

export const fetchUserWithOrderExists = createFetcher.post<{
  status_present: boolean;
}>(ROUTES.USERS.WITH_ORDER_EXISTS);
