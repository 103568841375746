import { CloseModal } from "assets";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { createContext, FC, useContext, useState, useEffect } from "react";
import classes from "styles/components/Modal.module.scss";

const ModalContext = createContext({
  Component: undefined as undefined | FC,
  openModal: (Component: FC) => {},
  closeModal: () => {},
  isModalOpen: false,
});

export const ModalProvider: FC = ({ children, ...props }) => {
  const [{ Component }, setModalComponent] = useState<{
    Component: undefined | FC;
  }>({
    Component: undefined,
  });
  const closeModal = () => setModalComponent({ Component: undefined });
  const openModal = (Component: FC) => setModalComponent({ Component });

  const isModalOpen = !!Component;

  useEffect(() => {
    if (isModalOpen) disableBodyScroll((document as unknown) as HTMLElement);
    else enableBodyScroll((document as unknown) as HTMLElement);
  }, [isModalOpen]);

  return (
    <ModalContext.Provider
      {...props}
      value={{
        Component: Component,
        openModal,
        closeModal,
        isModalOpen: !!Component,
      }}
    >
      <>
        {Component ? (
          <>
            <div className={classes.backdrop} onClick={closeModal}></div>
            <div className={classes.container}>
              <div className={classes.innercontainer}>
                <div className={classes.close}>
                  <div onClick={closeModal}>
                    <CloseModal />
                  </div>
                </div>
                <div className={classes.component}>
                  <Component />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {children}
      </>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
}

export const Modal: FC<ModalProps> = ({
  className,
  children,
  isOpen,
  onClose,
}) => {
  return isOpen ? (
    <>
      <div className={classes.backdrop} onClick={onClose}></div>
      <div className={[classes.container, className].join(" ")}>
        <div className={classes.innercontainer}>
          <div className={classes.close}>
            <div onClick={onClose}>
              <CloseModal />
            </div>
          </div>
          <div className={classes.component}>{children}</div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
