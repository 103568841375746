import React from "react";
import classes from "styles/components/Footer.module.scss";
import Section from "./Section";

export default function Footer() {
  return (
    <Section
      className={classes.container}
      innerClassName={classes.innerContainer}
    >
      <p>© 2022 reserved by IncDecentral.</p>
    </Section>
  );
}
