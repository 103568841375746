export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;

const route = (r: string) => `${API_HOST}/api${r}`;
const shopper = (r: string) =>
  `https://api.shopperapproved.com${r}/${process.env.NEXT_PUBLIC_SHOPPER_APPROVED_SITE_ID}?token=${process.env.NEXT_PUBLIC_SHOPPER_APPROVED_API_TOKEN}`;

export const ROUTES = {
  CONSTANTS: {
    STATES: route("/constants/states"),
    PRODUCTS: route("/constants/products"),
    ADDONS: route("/constants/addons"),
  },
  WORKFLOW: {
    STEP: (step: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | "recurring" | "addons") =>
      route(`/workflow/${step}`),
    ORDER: route("/workflow"),
    PENDING_ADDONS: route("/orders/pending"),
    HISTORY: route("/workflow/history"),
    CART: route("/workflow/cart"),
    ADD_COUPON: route("/workflow/coupon"),
    REMOVE_COUPON: route("/workflow/coupon/remove"),
    SUCCESS: route("/workflow/success"),
  },
  STRIPE: {
    PAYMENT_INTENT: route("/stripe"),
  },
  USERS: {
    AUTH: route("/auth"),
    PROFILE: route("/users"),
    EDIT: route("/users/update"),
    WITH_ORDER_EXISTS: route("/auth/fb"),
  },
  ORDERS: {
    CHECK: route("/orders/check"),
    LIST: route("/orders/cart"),
    COMPLETE: route("/orders/complete"),
    INCOMPLETE: route("/orders/incomplete"),
  },
  HUBSPOT: {
    SUBMIT_FORM:
      "https://api.hsforms.com/submissions/v3/integration/submit/" +
      process.env.NEXT_PUBLIC_WORKFLOW_FORM_ID,
    CONTACT_FORM:
      "https://api.hsforms.com/submissions/v3/integration/submit/21110615/8de15b21-624f-4d53-a10d-5642179ba498",
  },
  SHOPPER: {
    AGGREGATES: shopper("/aggregates/reviews"),
    REVIEWS: shopper("/reviews"),
  },
};
