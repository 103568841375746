import { useEffect, useState } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevPos, setPrevPos] = useState(0);

  useEffect(() => {
    setTimeout(() => setScrollPosition(prevPos), 0);
    // setScrollPosition(prevPos);
  }, [prevPos]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const updatePosition = () => {
        setPrevPos(window.pageYOffset);
      };
      window.addEventListener("scroll", updatePosition);
      updatePosition();
      return () => window.removeEventListener("scroll", updatePosition);
    }
  }, []);

  return {
    scrolled: scrollPosition,
    scrollingUp: prevPos < scrollPosition,
  };
};

export default useScrollPosition;
