import axios, { AxiosRequestConfig, Method } from "axios";

export const errorWrapper = <
  T extends { error: any } = { error: any; [key: string]: any },
  Props extends any[] = any[]
>(
  f: (...props: Props) => Promise<T>
) => async (...props: Props): Promise<T> => {
  try {
    const data = await f(...props);
    return data;
  } catch (error) {
    // @ts-ignore
    return { error };
  }
};

const getLocal = (key: string) => {
  const val = localStorage.getItem(key);
  try {
    return JSON.parse(val);
  } catch (error) {
    return null;
  }
  // return val === null || val === undefined ? null : JSON.parse(val);
};

interface Options {
  addSessionCreds?: boolean;
  addFirebaseCreds?: boolean;
}

export const fetcher = <
  Response = any,
  Wrapped extends { error: any } = {
    body?: Response;
    msg?: string;
    statusCode?: number;
    [key: string]: any;
    error: any;
  }
>(
  endpoint: string,
  method?: Method,
  { addSessionCreds, addFirebaseCreds }: Options = {}
) =>
  errorWrapper<Wrapped>(
    async (body?: Request, options: AxiosRequestConfig = {}) => {
      // Wrapped | { error: any }
      const { data } = await axios({
        method,
        url: endpoint,
        data: {
          ...(addSessionCreds
            ? {
                order_id: getLocal("order_id"),
                sessions_id: getLocal("sessions_id"),
                sessions_token: getLocal("sessions_token"),
                metadata: {
                  OrderId: getLocal("order_id"),
                },
              }
            : {}),
          ...(addFirebaseCreds
            ? {
                id_token: getLocal("id_token"),
                access_token: getLocal("access_token"),
              }
            : {}),
          ...body,
        },
        ...options,
      });
      return data;
    }
  );

export const createFetcher = {
  get: <Res = any>(endpoint: string, options?: Options) =>
    fetcher<Res>(endpoint, "GET", options),
  post: <Res = any>(endpoint: string, options?: Options) =>
    fetcher<Res>(endpoint, "POST", options),
  getExt: <Res extends { error: any } = { error: any }>(
    endpoint: string,
    options?: Options
  ) => fetcher<Res, Res>(endpoint, "GET", options),
};
