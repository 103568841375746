import React from "react";
import Image from "next/image";
import { ImgProps } from "interfaces";

// IMPORT IMAGES
import logo from "./logo.svg";
import phone from "./phone.svg";
import phoneFooter from "./phone_footer.svg";
import phoneBlue from "./phone_blue.svg";
import addressFooter from "./address_footer.svg";

import instagram from "./instagram.svg";
import twitter from "./twitter.svg";
import youtube from "./youtube.svg";
import linkedin from "./linkedin.svg";

import breadArrow from "./bread_arrow.svg";
import breadArrowFaded from "./bread_arrow_faded.svg";
import greenCheckRound from "./green_check_round.svg";
import warn from "./warn.svg";
import blackCheck from "./black_check.svg";
import whiteCheck from "./white_check.svg";

import info from "./info.svg";
import plusPink from "./plus_pink.svg";
import plusWhite from "./plus_white.svg";

import heroIllustration from "./hero_Illustration.svg";
import whyHero from "./why_hero.svg";
import apartImage from "./apart_image.svg";

import genieMan from "./genie_man.svg";

import taxes from "./taxes.svg";
import investor from "./investor.svg";
import star from "./star.svg";
import lowCost from "./low_cost.png";
import clock from "./clock.svg";
import file from "./file.svg";
import grow from "./grow.svg";
import book from "./book.svg";
import tag from "./tag.svg";
import box from "./box.svg";

import burger from "./burger.svg";

import chooseImage from "./choose_image.svg";
import closeModal from "./close_modal.svg";

import planSelect from "./plan_select.svg";
import workTime from "./work_time.svg";
import growBusiness from "./grow_business.svg";
import imageStage from "./image_stage.svg";

import dropArrowWhite from "./drop_arrow_white.svg";
import dropArrowSilver from "./drop_arrow_silver.svg";
import rightArrowGray from "./rightArrowGray.svg";
import blueWhiteDropArrow from "./blueWhiteDropArrow.svg";
import featureBullet from "./feature_bullet.svg";

import linkIcon from "./link.svg";
import linkIconWhite from "./link_white.svg";
import wave from "./wave.svg";

import tick from "./tick.svg";
import x from "./x.svg";

import moneyEyesEmoji from "./emoji_money_eyes.svg";

import ein from "./EIN.svg";
import building from "./building.svg";
import agent from "./agent.svg";
import doc from "./doc.svg";
import license from "./license.svg";
import filing from "./filing.svg";

import countrySelectArrow from "./country_select_arrow.svg";
import selectArrow from "./select_arrow.svg";
import close from "./close.svg";
import closeBlue from "./closeBlue.svg";
import closeGray from "./closeGray.svg";
import cart from "./cart.svg";

import facebookRound from "./facebook_round.svg";
import appleRound from "./apple_round.svg";
import googleRound from "./google_round.svg";
import google from "./google.svg";

import scatter1 from "./scatter1.png";
import scatter2 from "./scatter2.png";
import scatter3 from "./scatter3.png";
import scatter4 from "./scatter4.png";
import scatter5 from "./scatter5.png";
import scatter6 from "./scatter6.png";

import search from "./search.svg";
import errorMark from "./error_mark.svg";
import logout from "./logout.svg";
import dashboard from "./dashboard.svg";
import logoutPink from "./logout_pink.svg";
import dp from "./dp.svg";
import dpLone from "./dpLone.svg";
import edit from "./edit.svg";
import placeholder from "./placeholder.svg";
import man from "./man.svg";

import shopperApproved from "./shopper_approved.png";
import incdGlow from "./incdGlow.svg";
import trademarkia from "./trademarkia.png";
import trademarkiaWhite from "./trademarkia_white.png";

export const createImage = (image: StaticImageData, options: ImgProps = {}) => (
  props: ImgProps
) => <Image unoptimized {...options} {...props} src={image} />;

// EXPORT IMG COMPONENTS
export const Logo = createImage(logo);
export const Phone = createImage(phone);
export const PhoneFooter = createImage(phoneFooter);
export const PhoneBlue = createImage(phoneBlue);
export const AddressFooter = createImage(addressFooter);

export const Instagram = createImage(instagram);
export const Twitter = createImage(twitter);
export const Youtube = createImage(youtube);
export const Linkedin = createImage(linkedin);

export const BreadArrow = createImage(breadArrow);
export const BreadArrowFaded = createImage(breadArrowFaded);
export const GreenCheckRound = createImage(greenCheckRound);
export const Warn = createImage(warn);
export const BlackCheck = createImage(blackCheck);
export const WhiteCheck = createImage(whiteCheck);

export const PlusPink = createImage(plusPink);
export const PlusWhite = createImage(plusWhite);

export const HeroIllustration = createImage(heroIllustration);
export const WhyHero = createImage(whyHero);
export const ApartImage = createImage(apartImage);

export const GenieMan = createImage(genieMan);

export const Taxes = createImage(taxes);
export const Investor = createImage(investor);
export const Star = createImage(star);
export const LowCost = createImage(lowCost);
export const Clock = createImage(clock);
export const File = createImage(file);
export const Grow = createImage(grow);
export const Book = createImage(book);
export const Tag = createImage(tag);
export const Box = createImage(box);

export const Burger = createImage(burger);

export const ChooseImage = createImage(chooseImage);
export const CloseModal = createImage(closeModal);

export const PlanSelect = createImage(planSelect);
export const WorkTime = createImage(workTime);
export const GrowBusiness = createImage(growBusiness);
export const ImageStage = createImage(imageStage);

export const DropArrowWhite = createImage(dropArrowWhite);
export const DropArrowSilver = createImage(dropArrowSilver);
export const RightArrowGray = createImage(rightArrowGray);
export const BlueWhiteDropArrow = createImage(blueWhiteDropArrow);
export const FeatureBullet = createImage(featureBullet);

export const LinkIcon = createImage(linkIcon);
export const LinkIconWhite = createImage(linkIconWhite);
export const Wave = createImage(wave);

export const Tick = createImage(tick);
export const X = createImage(x);

export const MoneyEyesEmoji = createImage(moneyEyesEmoji);

export const EIN = createImage(ein);
export const Building = createImage(building);
export const Agent = createImage(agent);
export const Doc = createImage(doc);
export const License = createImage(license);
export const Filing = createImage(filing);

export const CountrySelectArrow = createImage(countrySelectArrow);
export const SelectArrow = createImage(selectArrow);
export const Close = createImage(close);
export const CloseBlue = createImage(closeBlue);
export const CloseGray = createImage(closeGray);
export const Cart = createImage(cart);

export const FacebookRound = createImage(facebookRound);
export const AppleRound = createImage(appleRound);
export const GoogleRound = createImage(googleRound);
export const Google = createImage(google);

export const Scatter1 = createImage(scatter1);
export const Scatter2 = createImage(scatter2);
export const Scatter3 = createImage(scatter3);
export const Scatter4 = createImage(scatter4);
export const Scatter5 = createImage(scatter5);
export const Scatter6 = createImage(scatter6);

export const SearchIcon = createImage(search);
export const ErrorMark = createImage(errorMark);
export const Logout = createImage(logout);
export const Dashboard = createImage(dashboard);
export const LogoutPink = createImage(logoutPink);
export const DP = createImage(dp);
export const DPLone = createImage(dpLone);
export const Edit = createImage(edit);
export const Placeholder = createImage(placeholder);
export const Man = createImage(man);

export const ShopperApproved = createImage(shopperApproved);
export const IncdGlow = createImage(incdGlow);
export const Trademarkia = createImage(trademarkia);
export const TrademarkiaWhite = createImage(trademarkiaWhite);

export const Checked = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1446_4241"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="19"
      height="19"
    >
      <rect
        x="0.523438"
        y="0.535156"
        width="18"
        height="18"
        rx="5"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_1446_4241)">
      <path
        d="M17.0234 0.535156H2.02344C1.62561 0.535156 1.24408 0.693191 0.962777 0.974496C0.681473 1.2558 0.523438 1.63733 0.523438 2.03516V17.0352C0.523438 17.433 0.681473 17.8145 0.962777 18.0958C1.24408 18.3771 1.62561 18.5352 2.02344 18.5352H17.0234C17.4213 18.5352 17.8028 18.3771 18.0841 18.0958C18.3654 17.8145 18.5234 17.433 18.5234 17.0352V2.03516C18.5234 1.63733 18.3654 1.2558 18.0841 0.974496C17.8028 0.693191 17.4213 0.535156 17.0234 0.535156ZM8.02344 13.6602L4.27344 9.94241L5.46594 8.78516L8.02344 11.2947L13.5802 5.78516L14.7734 6.96791L8.02344 13.6602Z"
        fill="#E92169"
      />
    </g>
  </svg>
);
export const Unchecked = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.523438"
      y="0.535156"
      width="18"
      height="18"
      rx="5"
      stroke="#E92169"
    />
  </svg>
);

export const Orders = () => (
  <svg
    width="20"
    height="26"
    viewBox="0 0 20 26"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75012 6.64991C3.75012 3.19812 6.54833 0.399902 10.0001 0.399902C13.4519 0.399902 16.2501 3.19812 16.2501 6.64991H18.7501C19.4405 6.64991 20.0001 7.20951 20.0001 7.89991V24.1499C20.0001 24.8403 19.4405 25.3999 18.7501 25.3999H1.25012C0.55972 25.3999 0.00012207 24.8403 0.00012207 24.1499V7.89991C0.00012207 7.20951 0.55972 6.64991 1.25012 6.64991H3.75012ZM6.25012 6.64991H13.7501C13.7501 4.57888 12.0711 2.89991 10.0001 2.89991C7.92909 2.89991 6.25012 4.57888 6.25012 6.64991Z"
    />
  </svg>
);

export const Home = () => (
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.7806 22.3771H12.2546V15.8583C12.2546 15.7831 12.2034 15.7329 12.1266 15.7329L7.57275 15.7331C7.49602 15.7331 7.4448 15.7833 7.4448 15.8585V22.3773L1.91876 22.3771C0.869793 22.3771 0 21.5247 0 20.4967V9.91616C0 9.26433 0.281427 8.63752 0.74183 8.16114L0.767349 8.13613L8.41727 1.46687C9.21032 0.714656 10.5152 0.714656 11.3083 1.46687L18.9577 8.1612C19.4439 8.61242 19.6996 9.26443 19.6996 9.91623V20.4968C19.6996 21.5498 18.8553 22.3772 17.7808 22.3772L17.7806 22.3771Z" />
  </svg>
);

export const Profile = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.75005 0.354614H21.2499C23.321 0.354614 25 2.03357 25 4.10467V21.6046C25 23.6757 23.321 25.3546 21.2499 25.3546H3.75005C1.67895 25.3546 0 23.6757 0 21.6046V4.10467C0 2.03357 1.67895 0.354614 3.75005 0.354614ZM6.24995 9.10456H18.7505C19.4409 9.10456 20.0004 8.545 20.0004 7.85461C20.0004 7.16423 19.4409 6.60445 18.7505 6.60445H6.24995C5.55956 6.60445 5 7.16423 5 7.85461C5 8.545 5.55956 9.10456 6.24995 9.10456ZM6.24995 14.1046H18.7505C19.4409 14.1046 20.0004 13.545 20.0004 12.8546C20.0004 12.1642 19.4409 11.6047 18.7505 11.6047H6.24995C5.55956 11.6047 5 12.1642 5 12.8546C5 13.545 5.55956 14.1046 6.24995 14.1046ZM6.24995 19.1048H12.5002C13.1906 19.1048 13.7502 18.545 13.7502 17.8546C13.7502 17.1642 13.1906 16.6047 12.5002 16.6047H6.24995C5.55956 16.6047 5 17.1642 5 17.8546C5 18.545 5.55956 19.1048 6.24995 19.1048Z" />
  </svg>
);

export const LogoRound = () => (
  <svg
    width="77"
    height="78"
    viewBox="0 0 77 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2187 11.435L30.382 11.9271C15.8922 15.8096 7.29339 30.7033 11.1759 45.193L11.6681 47.0298C15.5506 61.5195 30.4442 70.1183 44.9339 66.2358L46.7707 65.7437C61.2604 61.8612 69.8593 46.9675 65.9768 32.4778L65.4846 30.641C61.6021 16.1513 46.7085 7.55246 32.2187 11.435Z"
      fill="#E92169"
    />
    <path
      d="M68.8339 30.7287C64.3564 14.0184 47.1803 4.10178 30.47 8.57929C13.7597 13.0568 3.84311 30.2329 8.32062 46.9431C12.7981 63.6534 29.9742 73.57 46.6845 69.0925C63.3947 64.615 73.3114 47.4389 68.8339 30.7287Z"
      fill="#E61D5D"
    />
    <path
      d="M34.4804 41.3675L34.0189 32.0745C34.0088 31.8701 34.2236 31.7313 34.4057 31.8245L42.3249 35.8751C42.5367 35.9835 42.6744 36.1968 42.6863 36.4345L43.1435 45.7372C43.1536 45.9415 42.9386 46.0803 42.7565 45.9869L34.8409 41.9263C34.6296 41.8178 34.4922 41.6048 34.4804 41.3675Z"
      fill="#FFEBF2"
    />
    <path
      d="M46.8479 34.237L46.824 33.8834L46.5171 33.7061L37.0298 28.2268L44.7336 23.0879L55.0991 28.3222L55.449 39.1987L47.5362 44.3885L46.8479 34.237ZM36.8444 28.1198C36.8445 28.1198 36.8445 28.1198 36.8446 28.12L36.8444 28.1198L36.7778 28.235L36.8444 28.1198Z"
      fill="#FFEBF2"
    />
    <path
      d="M36.8444 28.1198C36.8445 28.1198 36.8445 28.1198 36.8446 28.12L36.8444 28.1198ZM36.8444 28.1198L36.7778 28.235L36.8444 28.1198ZM46.8479 34.237L46.824 33.8834L46.5171 33.7061L37.0298 28.2268L44.7336 23.0879L55.0991 28.3222L55.449 39.1987L47.5362 44.3885L46.8479 34.237Z"
      stroke="#E92169"
      stroke-width="1.32991"
    />
    <path
      d="M30.3067 43.4343L30.3306 43.7879L30.6375 43.9652L39.1982 48.9092C39.6251 49.1557 39.6446 49.7647 39.2346 50.0382L32.4209 54.5832L22.0554 49.3491L21.7056 38.4724L28.6672 33.9067C29.0925 33.6276 29.6609 33.9102 29.6952 34.4178L30.3067 43.4343Z"
      fill="#FFEBF2"
      stroke="#E92169"
      stroke-width="1.32991"
    />
    <path
      d="M46.8479 34.237L46.824 33.8834L46.5171 33.7061L37.0298 28.2268L44.7336 23.0879L55.0991 28.3222L55.449 39.1987L47.5362 44.3885L46.8479 34.237ZM36.8444 28.1198C36.8445 28.1198 36.8445 28.1198 36.8446 28.12L36.8444 28.1198L36.7778 28.235L36.8444 28.1198Z"
      fill="#FFEBF2"
    />
    <path
      d="M36.8444 28.1198C36.8445 28.1198 36.8445 28.1198 36.8446 28.12L36.8444 28.1198ZM36.8444 28.1198L36.7778 28.235L36.8444 28.1198ZM46.8479 34.237L46.824 33.8834L46.5171 33.7061L37.0298 28.2268L44.7336 23.0879L55.0991 28.3222L55.449 39.1987L47.5362 44.3885L46.8479 34.237Z"
      stroke="#E92169"
      stroke-width="1.32991"
    />
    <path
      d="M30.3067 43.4343L30.3306 43.7879L30.6375 43.9652L39.1982 48.9092C39.6251 49.1557 39.6446 49.7647 39.2346 50.0382L32.4209 54.5832L22.0554 49.3491L21.7056 38.4724L28.6672 33.9067C29.0925 33.6276 29.6609 33.9102 29.6952 34.4178L30.3067 43.4343Z"
      fill="#FFEBF2"
      stroke="#E92169"
      stroke-width="1.32991"
    />
    <path
      d="M28.9531 34.5273L29.5956 44.2721L22.599 49.0172L22.2583 38.928L28.9531 34.5273Z"
      fill="#FCDBE7"
    />
    <path
      d="M48.1362 43.4152L47.4639 33.5139L54.444 28.748L54.7765 38.9522L48.1362 43.4152Z"
      fill="#FFD3E3"
    />
  </svg>
);

export const Info = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="#A9A9A9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15218 0C4.08698 0 0.790771 3.13438 0.790771 7C0.790771 10.8656 4.08698 14 8.15218 14C12.2174 14 15.5136 10.8656 15.5136 7C15.5136 3.13438 12.2174 0 8.15218 0ZM8.678 10.375C8.678 10.4438 8.61884 10.5 8.54655 10.5H7.75782C7.68552 10.5 7.62637 10.4438 7.62637 10.375V6.125C7.62637 6.05625 7.68552 6 7.75782 6H8.54655C8.61884 6 8.678 6.05625 8.678 6.125V10.375ZM8.15218 5C7.94579 4.99599 7.74927 4.91522 7.60479 4.775C7.46032 4.63478 7.3794 4.4463 7.3794 4.25C7.3794 4.0537 7.46032 3.86522 7.60479 3.725C7.74927 3.58478 7.94579 3.50401 8.15218 3.5C8.35858 3.50401 8.5551 3.58478 8.69957 3.725C8.84405 3.86522 8.92497 4.0537 8.92497 4.25C8.92497 4.4463 8.84405 4.63478 8.69957 4.775C8.5551 4.91522 8.35858 4.99599 8.15218 5Z"
      fill-opacity="0.8"
    />
  </svg>
);

export const ArrowGray = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13147 15.5314C9.75631 14.9065 9.75631 13.8935 9.13147 13.2686L3.86283 8L9.13147 2.73137C9.75631 2.10653 9.75631 1.09347 9.13147 0.468629C8.50663 -0.15621 7.49356 -0.15621 6.86872 0.468629L0.468714 6.86863C-0.156127 7.49347 -0.156127 8.50653 0.468714 9.13137L6.86872 15.5314C7.49356 16.1562 8.50663 16.1562 9.13147 15.5314Z"
      fill="#C1C1C1"
    />
  </svg>
);
