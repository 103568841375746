import {
  Burger,
  Cart,
  CloseBlue,
  Dashboard,
  DP,
  DPLone,
  DropArrowSilver,
  Logo,
  LogoutPink,
  Phone,
  Profile,
  Trademarkia,
} from "assets";
import React, { useEffect, useState } from "react";
import classes from "styles/components/Header.module.scss";
import Button from "./Button";
import Section from "./Section";
import { useRouter } from "next/router";
import { useScroll } from "contexts/scroll";
import useScrollPosition from "hooks/useScrollPosition";
import { toggle, useGlobals } from "contexts/globals";
import Link from "next/link";
import { useUser } from "contexts/user";
import useOutsideClick from "hooks/useOutsideClick";
import Image from "next/image";
import { PAGES } from "config/links";
import { CONTACT_NUMBER } from "config/constants";

export default function Header() {
  const {
    pathname,
    query: { step: stepStr },
  } = useRouter();
  const { scrollTo } = useScroll();
  const { scrolled } = useScrollPosition();
  const { setCartOpen } = useGlobals();
  const { user, safeLogout } = useUser();
  const step = parseInt(stepStr?.toString() || "0");
  const [dropOpen, setDropOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const dropRef = useOutsideClick(() => {
    if (dropOpen) setDropOpen(false);
  }, [dropOpen, setDropOpen]);

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  return (
    <Section
      className={[
        classes.container,
        ["/learning_center", "privacy_policy"].find((p) => pathname.includes(p))
          ? classes.blue
          : "",
      ].join(" ")}
      innerClassName={classes.innerContainer}
      style={{
        boxShadow:
          scrolled && !pathname.includes("workflow")
            ? `rgb(225 225 225 / 24%) 0px 4px 50px 0px`
            : undefined,
      }}
    >
      <a href={PAGES.LANDING}>
        <div className={classes.logo}>
          <Logo />
          <div className={classes.text}>
            <Trademarkia objectFit="contain" width={120} height={17} />
            <h1>IncDecentral</h1>
          </div>
        </div>
      </a>
      <div
        className={[
          classes.nav,
          user ? classes.in : classes.out,
          navOpen ? classes.open : classes.closed,
        ].join(" ")}
      >
        <div className={classes.innerNav}>
          <div className={classes.links}>
            <a href={`tel:${CONTACT_NUMBER.US}`} className={classes.phoneLink}>
              <div className={[classes.phone, classes.navLink].join(" ")}>
                <Phone />
                <p>{CONTACT_NUMBER.US}</p>
              </div>
            </a>
            {!pathname.includes("workflow") && (
              <>
                <a href={PAGES.WHY_US}>
                  <p
                    className={[
                      pathname === "/why_us"
                        ? classes.active
                        : classes.inactive,
                      classes.navLink,
                    ].join(" ")}
                  >
                    Why IncDecentral?
                  </p>
                </a>
                <a href={PAGES.LEARNING_CENTER}>
                  <p
                    className={[
                      pathname === "/learning_center"
                        ? classes.active
                        : classes.inactive,
                      classes.navLink,
                    ].join(" ")}
                  >
                    Learning Center
                  </p>
                </a>
                <Link href="/workflow">
                  <p
                    className={classes.navLink}
                    onClick={() => scrollTo("plans")}
                  >
                    Plans
                  </p>
                </Link>
              </>
            )}
          </div>
          {user ? (
            <div className={classes.user} ref={dropRef}>
              <div
                className={classes.view}
                onClick={() => setDropOpen((p) => !p)}
              >
                <div className={classes.dp}>
                  {user.photoURL ? (
                    <Image unoptimized layout="fill" src={user.photoURL} />
                  ) : (
                    <DP layout="fill" />
                  )}
                </div>
                <span>{user.displayName?.split(" ")?.[0] || user.email}</span>
                <div className={classes.arrow}>
                  <DropArrowSilver />
                </div>
              </div>

              <div
                className={[
                  classes.dropdown,
                  dropOpen ? classes.open : classes.closed,
                ].join(" ")}
              >
                <Link href="/dashboard">
                  <div
                    onClick={() => setDropOpen(false)}
                    className={classes.item}
                  >
                    <div className={classes.icon}>
                      <Dashboard />
                    </div>
                    <span>My Dashboard</span>
                  </div>
                </Link>
                <div
                  onClick={() => {
                    setDropOpen(false);
                    safeLogout();
                  }}
                  className={[classes.item, classes.button].join(" ")}
                >
                  <div className={classes.icon}>
                    <LogoutPink />
                  </div>
                  <span>Log out</span>
                </div>
              </div>
            </div>
          ) : (
            <Button
              className={classes.button}
              variant="outlined"
              link={"/login"}
            >
              Login
            </Button>
          )}
          {!user && (
            <div className={[classes.dp, classes.lone].join(" ")}>
              <DPLone />
            </div>
          )}
        </div>
      </div>
      {pathname.includes("workflow") ? (
        step > 1 ? (
          <div className={classes.cart} onClick={() => toggle(setCartOpen)}>
            <Cart />
          </div>
        ) : (
          <></>
        )
      ) : (
        <div onClick={() => setNavOpen((p) => !p)} className={classes.burger}>
          {navOpen ? <CloseBlue /> : <Burger />}
        </div>
      )}
    </Section>
  );
}
