import { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import DashboardLayout from "./Dashboard";
import LandingLayout from "./Landing";

interface Props {}

const Layout: NextPage<Props> = (props) => {
  const { pathname } = useRouter();

  return (
    <>
      <Head>
        {/* Start of HubSpot Embed Code */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/21110615.js"
        ></script>
        {/* End of HubSpot Embed Code */}
      </Head>
      {pathname.includes("/dashboard") ? (
        <DashboardLayout {...props} />
      ) : (
        <LandingLayout {...props} />
      )}
    </>
  );
};

export default Layout;
