import { NextPage } from "next";

import Footer from "components/Footer";
import Header from "components/Header";

interface Props {}

const LandingLayout: NextPage<Props> = ({ children }) => {
  return (
    <>
      <div className="App">
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default LandingLayout;
