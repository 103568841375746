import { useState } from "react";

export default function useHovering() {
  const [hovering, setHovering] = useState(false);
  return {
    hovering,
    hoverProps: {
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
    },
  };
}
