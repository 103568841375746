import { useRouter } from "next/router";
import {
  createContext,
  FC,
  RefObject,
  useContext,
  useState,
  useEffect,
} from "react";

const ScrollContext = createContext({
  createScroller: (key: string, ref: any) => { },
  scrollTo: (key: string) => { },
  refs: {} as { [key: string]: RefObject<any> },
});

export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider: FC = (props) => {
  const [refs, setRefs] = useState<{ [key: string]: RefObject<any> }>({});
  const { query } = useRouter();

  const createScroller = (key: string, ref: RefObject<any>) =>
    setRefs((p) => ({ ...p, [key]: ref }));

  const scrollTo = (key: string, offset?: number) =>
    scrollToRef(refs[key], offset);

  const scrollToRef = (ref: RefObject<any>, offset = 100) =>
    ref?.current?.offsetTop && (typeof window !== "undefined") &&
    window.scrollTo(ref.current.offsetLeft, ref.current.offsetTop - offset);
  useEffect(() => {
    if (query.scroll) scrollTo(query.scroll.toString());
  }, [refs]);

  return (
    <ScrollContext.Provider
      {...props}
      value={{ createScroller, scrollTo, refs }}
    />
  );
};
