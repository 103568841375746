import { initializeApp } from "firebase/app";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  User,
} from "firebase/auth";
import { toast } from "react-toastify";
import { getLocal } from "utils/getLocal";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_apiKey,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_authDomain,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_projectId,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_storageBucket,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_messagingSenderId,
  appId: process.env.NEXT_PUBLIC_FIREBASE_appId,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_measurementId,
};

const app = initializeApp(firebaseConfig);
export default app;

const provider = new GoogleAuthProvider();
export const auth = getAuth();

export const loginWithGoogle = () =>
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (typeof window !== "undefined")
        window.localStorage.setItem(
          "access_token",
          JSON.stringify(credential.accessToken)
        );
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });

export const loginWithEmail = (
  {
    email,
    password,
    redirected,
  }: { email: string; password: string; redirected?: boolean },
  callback?: () => void
) =>
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      if (typeof window !== "undefined")
        window.localStorage.setItem(
          "access_token",
          // @ts-ignore
          JSON.stringify(userCredential.user.accessToken)
        );
      // ...
      toast.success(`Logged In as ${email}`);
      callback?.();
      return user;
    })
    .catch(({ code }) => {
      switch (code) {
        case "auth/user-not-found":
          toast.error(`Email ID not found`);
          break;
        case "auth/wrong-password":
          toast.error(
            redirected
              ? `User with email ${email} already exists and you entered the wrong password`
              : `Incorrect password for ${email}`
          );
          break;
        case "auth/invalid-email":
          toast.error("Email is invalid");
          break;
        default:
          toast.error(code);
      }
    });

export const createUser = ({ email, password }) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      toast.success(`New user created ${email}`);
      return user;
      // ...
    })
    .catch(({ code }) => {
      switch (code) {
        case "auth/weak-password":
          toast.error("Password must be atleast 6 characters long");
          break;
        case "auth/email-already-in-use":
          loginWithEmail({ email, password, redirected: true });
          break;
        default:
          toast.error(code);
      }
    });

export const logout = async () => {
  const order_id = getLocal("order_id");
  if (order_id) {
    const localKeys = [
      "sessions_id",
      "sessions_token",
      "skipped",
      "formOpen",
      "id_token",
      "maxPage",
      "formValues",
      "business_name",
      "order_id",
    ];
    localKeys.forEach((i) => localStorage.removeItem(i));
  }
  await signOut(auth);
};

export const resetLink = (email: string) =>
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      toast.success(`Password reset email send to ${email}`);
    })
    .catch(({ code }) => {
      switch (code) {
        case "auth/invalid-email":
          toast.error("Invalid Email");
          break;
        case "auth/user-not-found":
          toast.error(`Email not found`);
          break;
        default:
          toast.error(code);
      }
    });

export const resetPassword = (
  code: string,
  newPassword: string,
  cb: () => void
) =>
  confirmPasswordReset(auth, code, newPassword)
    .then(function() {
      toast.success("Password Reset Successful");
      cb?.();
    })
    .catch(function({ code }) {
      toast.error(code);
    });

export const changePassword = (
  newPassword: string
): Promise<{ success?: boolean; error?: any }> =>
  updatePassword(auth.currentUser, newPassword)
    .then(() => {
      toast.success("Password Reset Successful");
      return { success: true };
    })
    .catch(({ code }) => {
      toast.error(code);
      return { error: code };
    });
