import {
  Burger,
  CloseBlue,
  CloseGray,
  DP,
  Edit,
  IncdGlow,
  Logo,
  Logout,
  Orders,
  Profile,
  TrademarkiaWhite,
} from "assets";
import { useGlobals } from "contexts/globals";
import { useUser } from "contexts/user";
import { NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import classes from "styles/Layout.module.scss";
import { scrollbarVisible } from "utils";
import { formatDate } from "utils/format";

interface Props {}

const menu = [
  //   { Icon: Home, label: "Home", link: "" },
  { Icon: Orders, label: "My orders", link: "/orders" },
  { Icon: Profile, label: "Profile", link: "/profile" },
];

const DashboardLayout: NextPage<Props> = ({ children }) => {
  const { pathname, push } = useRouter();
  const { idToken } = useUser();
  const [paneOpen, setPaneOpen] = useState(false);
  const { user, profile, safeLogout } = useUser();
  const [isDashScrollable, setIsDashScrollable] = useState(false);
  const { helperOpen, setHelperOpen } = useGlobals();
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    setIsDashScrollable(scrollbarVisible(contentRef));
    const interval = setInterval(
      () => setIsDashScrollable(scrollbarVisible(contentRef)),
      500
    );
    return () => clearInterval(interval);
  }, [contentRef.current, setIsDashScrollable]);

  const now = new Date();
  let hours = now.getHours();

  useEffect(() => {
    if (!idToken) push("/login");
  }, [idToken]);

  useEffect(() => {
    setPaneOpen(false);
  }, [pathname]);

  return (
    <>
      <div className={classes.dashboard}>
        <div
          className={[
            classes.pane,
            paneOpen ? classes.open : classes.closed,
          ].join(" ")}
        >
          <Link href="/">
            <div className={classes.logo}>
              <Logo />
              <div className={classes.text}>
                <TrademarkiaWhite objectFit="contain" width={120} height={17} />
                <h1>IncDecentral</h1>
              </div>
            </div>
          </Link>
          <div className={classes.menu}>
            {menu.map(({ Icon, label, link }) => (
              <Link key={label + link} href={`/dashboard${link}`}>
                <div
                  className={[
                    classes.item,
                    pathname.includes(`/dashboard${link}`)
                      ? classes.active
                      : classes.inactive,
                  ].join(" ")}
                >
                  <Icon />
                  <p>{label}</p>
                </div>
              </Link>
            ))}
          </div>
          <div onClick={safeLogout} className={classes.logout}>
            <Logout />
            <p>Log out</p>
          </div>
        </div>
        <div ref={contentRef} className={classes.content}>
          <div className={classes.nav}>
            <Link href="/">
              <div className={classes.logo}>
                <Logo />
                <h1>IncDecentral</h1>
              </div>
            </Link>
            <div
              onClick={() => setPaneOpen((p) => !p)}
              className={classes.burger}
            >
              {paneOpen ? <CloseBlue /> : <Burger />}
            </div>
          </div>
          <div className={classes.children}>{children}</div>
        </div>
        <div
          style={
            isDashScrollable ? undefined : { borderLeft: "1px solid #636c93" }
          }
          className={[
            classes.helper,
            helperOpen ? classes.open : classes.closed,
          ].join(" ")}
        >
          <div className={classes.helperInner}>
            {profile && (
              <>
                <div className={classes.head}>
                  <div className={classes.text}>
                    <p className={classes.hello}>Hello {profile.full_name},</p>
                    <p className={classes.greet}>
                      Good{" "}
                      {hours >= 16
                        ? "Evening"
                        : hours >= 12
                        ? "Afternoon"
                        : "Morning"}
                    </p>
                  </div>
                  <div
                    onClick={() => setHelperOpen(false)}
                    className={classes.close}
                  >
                    <CloseGray />
                  </div>
                </div>
                <div className={classes.profile}>
                  <div className={classes.image}>
                    <IncdGlow />
                    <div className={classes.dp}>
                      {user?.photoURL ? (
                        <img src={user.photoURL} alt="" />
                      ) : (
                        <DP />
                      )}
                    </div>
                  </div>
                  <div className={classes.details}>
                    <p className={classes.name}>{profile.full_name}</p>
                    <p className={classes.joined}>
                      joined{" "}
                      {formatDate(profile.CreatedAt, {
                        month: "long",
                        formatBy: ({ date, month, year }) =>
                          `${date} ${month} ${year}`,
                      })}
                    </p>
                    <Link href="/dashboard/profile?edit=true">
                      <button className={classes.edit}>
                        <Edit />
                        <p>Edit Profile</p>
                      </button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
