import useHovering from "hooks/useHovering";
import Link from "next/link";
import React, { FC, Fragment } from "react";
import classes from "styles/components/Button.module.scss";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  theme?: "primary" | "secondary";
  variant?: "outlined" | "filled";
  size?: "m" | "s";
  link?: string;
  color?: string;
  flex?: boolean;
  icon?: FC;
  hoverIcon?: FC;
  element?: React.ElementType;
}

export default function Button({
  theme = "primary",
  variant = "filled",
  size = "m",
  link,
  color,
  className,
  style,
  flex,
  icon: Icon,
  hoverIcon: HoverIcon,
  children,
  element: Element = "button",
  disabled,
  onClick,
  type = "submit",
  ...props
}: Props) {
  const Wrapper: FC = link
    ? (props) => <Link href={link} {...props} />
    : Fragment;
  const { hovering, hoverProps } = useHovering();
  return (
    <Wrapper>
      <Element
        {...hoverProps}
        {...props}
        onClick={disabled ? undefined : onClick}
        type={type === "submit" && disabled ? "button" : type}
        style={{
          ...style,
          color: color
            ? variant === "outlined"
              ? color
              : "#fff"
            : style?.color,
          backgroundColor: color
            ? variant === "outlined"
              ? "#fff"
              : color
            : style?.backgroundColor,
          borderColor: color || style?.borderColor,
          display: flex || Icon ? "flex" : undefined,
        }}
        className={[
          classes.container,
          className,
          classes[theme],
          classes[variant],
          classes[size],
          disabled ? classes.disabled : "",
        ].join(" ")}
      >
        {Icon && (hovering && HoverIcon ? <HoverIcon /> : <Icon />)}
        {Icon ? <p>{children}</p> : children}
      </Element>
    </Wrapper>
  );
}
