import Layout from "components/Layout";
import { GlobalsProvider } from "contexts/globals";
import { ModalProvider } from "contexts/modal";
import { ScrollProvider } from "contexts/scroll";
import { UserProvider } from "contexts/user";
import { AppProps } from "next/app";
import React, { useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "styles/index.scss";
import Head from "next/head";
const isProduction = process.env.NEXT_DEV_ENV === "prod";

toast.configure({ theme: "colored", position: "bottom-right" });

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        {isProduction && (
          <>
            <script src="https://www.googletagmanager.com/gtag/js?id=G-SJCV8SXL2G"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-SJCV8SXL2G');
              gtag('config', 'AW-10861151372');
            `,
              }}
            ></script>
          </>
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2898302,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '411824119161575');
            fbq('track', 'PageView');
            `,
          }}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W24QXSF');
            `,
          }}
        ></script>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <meta
          name="description"
          content="Form your business the right way with our attorney-led business filing service with IncDecentral."
        />
        <meta
          name="keywords"
          content="IncDecentral, Trademarkia, Business, Incorporation, Corporate, LLC, Private Limited, Pvt Ltd, Inc, Partnership, Registration, Filing, Lawyer, Legal"
        />
        <meta name="author" content="Trademarkia" />
        <meta name="copyright" content="Trademarkia" />
        <meta name="language" content="en" />
        <meta name="url" content="https://www.incdecentral.com" />
        <meta
          name="category"
          content="Business Incorporation, Legal, LegalTech"
        />
        <meta name="coverage" content="Worldwide" />
        <meta name="rating" content="General" />
        <meta name="og:email" content="cs@incdecentral.com" />
        <meta name="og:country-name" content="USA" />
        <meta
          name="og:region"
          content="California, Palo Alto, Menlo Park, Mountain View, United States, America"
        />
        <meta
          property="og:site_name"
          content="IncDecentral- Quickly form your business in under 5 minutes"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="IncDecentral- Quickly form your business in under 5 minutes"
        />
        <meta
          property="og:description"
          content="Form your business the right way with our attorney-led business filing service with IncDecentral."
        />
        <meta property="og:url" content="https://www.incdecentral.com" />
        <meta
          property="og:site_name"
          content="IncDecentral- Quickly form your business in under 5 minutes"
        />
        <meta name="twitter:card" content="website" />
        <meta
          name="twitter:site"
          content="IncDecentral- Quickly form your business in under 5 minutes"
        />
        <meta
          name="twitter:title"
          content="IncDecentral- Quickly form your business in under 5 minutes"
        />
        <meta
          name="twitter:description"
          content="Form your business the right way with our attorney-led business filing service with IncDecentral."
        />
        <meta name="twitter:creator" content="@incdecentral" />
        <meta
          itemProp="name"
          content="IncDecentral- Quickly form your business in under 5 minutes"
        />
        <meta
          itemProp="description"
          content="Form your business the right way with our attorney-led business filing service with IncDecentral."
        />
        <meta name="theme-color" content="#E92269" />
        <link rel="canonical" href="https://www.incdecentral.com" />
        <meta
          property="twitter:image"
          content="https://assets.web.incdecentral.com/logo.svg"
        />
        <meta
          property="og:image"
          itemProp="image"
          content="https://assets.web.incdecentral.com/logo.svg"
        />
        <link
          rel="apple-touch-icon"
          href="https://assets.web.incdecentral.com/logo.svg"
        />
        <link rel="icon" href="https://assets.web.incdecentral.com/logo.svg" />
        <title>
          IncDecentral- Quickly form your business in under 5 minutes
        </title>
      </Head>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-W24QXSF"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none", visibility: "hidden" }}
          src="https://www.facebook.com/tr?id=411824119161575&ev=PageView&noscript=1"
        />
      </noscript>
      <GlobalsProvider>
        <ScrollProvider>
          <ParallaxProvider>
            <ModalProvider>
              <UserProvider>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </UserProvider>
            </ModalProvider>
          </ParallaxProvider>
        </ScrollProvider>
      </GlobalsProvider>
      {/* </Elements> */}
    </>
  );
}
